import React, { useEffect } from 'react';
import { navigate } from 'gatsby';
import Layout from '../components/Layout';
import NavOne from '../components/NavOne';
import PageHeader from '../components/PageHeader';
import Footer from '../components/Footer';
import Deparments from '../components/Departments';

const FaqPage = () => {
  // useEffect(() => {
  //   navigate('/inprogress/departments');
  // }, []);
  return (
    <Layout pageTitle='Departments | LUTH'>
      <NavOne />
      <PageHeader title='Departments' />
      <Deparments />
      <Footer />
    </Layout>
  );
};

export default FaqPage;
